body {
  margin: 0;
  padding: 0;
  background-color: #ebeef0;
  box-sizing: border-box;
}
img {
  max-width: 100%;
  margin: 0 auto;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.h-100 {
  height: 100%;
}
.boxContainer {
  margin: 15px;
}
@media (max-width: 767px) {
  .boxContainer {
    margin: 10px;
  }
}
.boxWrapper {
  background-color: #fff;
  border: 1px solid #eaeef1;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  padding: 24px;
}
@media (max-width: 767px) {
  .boxWrapper {
    padding: 12px;
  }
}
.boxHead {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  margin: 0 0 15px;
}
.boxHead h2 {
  margin: 0;
  text-transform: uppercase;
}
.boxSearch {
  display: flex;
  margin-bottom: 15px;
}
.space-block {
  display: flex !important;
}
.space-flex {
  display: block !important;
}
.align-item-center {
  align-items: center;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-end {
  justify-content: flex-end;
}
.mainModalHeader h3 {
  padding: 0 15px;
}
.mainModalBody {
  height: 65vh;
  overflow: hidden auto;
}
.ant-modal-body::-webkit-scrollbar {
  width: 10px;
}
.ant-modal-body::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.ant-modal-body::-webkit-scrollbar-thumb {
  background: #888;
}
.ant-modal-body::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.ant-picker-calendar-mode-switch {
  display: none;
}

.ant-picker-content thead tr th {
  font-weight: bold !important;
  padding: 10px !important;
  background: #f0f0f0 !important;
}
.ant-picker-content thead tr th:nth-child(6),
.ant-picker-content thead tr th:nth-child(7) {
  color: #ff0000 !important;
}
.ant-picker-content tbody tr td:nth-child(6),
.ant-picker-content tbody tr td:nth-child(7) {
  color: #ff0000 !important;
}
.ant-menu-overflow-item {
  margin-left: auto;
}
@media (max-width: 767px) {
  .ant-card-body {
    padding: 12px !important;
  }
}
